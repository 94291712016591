//
// Navs
//

nav {
  @extend .smono;

  a {
    color: $black;
    text-decoration: none;

    .homepage &,
    #footer &,
    .about & {
      color: $white;
    }
  }
}

.nav--primary_alt {
    
  li:last-child {
    a {
      @extend .btn;
      padding-bottom: 0.375rem;
      padding-top: 0.375rem;
      position: relative;
      top: -4px;
    }
  }
}

.nav--footer_all {
  li:last-child {
    a {
      @extend .btn;
      background-color: $white !important;
      color: $blue !important;
      padding-bottom: 0.375rem;
      padding-top: 0.375rem;
      position: relative;
      top: -4px;
    }
  }
}

.nav--filter {
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 8px;
    margin-right: 10px;
  }

  button {
    appearance: none;
    background-color: $white;
    border: 1px solid $black;
    border-radius: 5px;
    color: $black;
    display: inline-block;
    padding: 5px 8px;
    @extend .smono;
    text-decoration: none;

    &.active,
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}

// active states
.approach #header .nav-link.Approach a { text-decoration: underline; }
.work #header .nav-link.Work a,
.case--study_detail #header .nav-link.Work a { text-decoration: underline; }
.blog #header .nav-link.SQUADLAND a,
.blog--post_detail #header .nav-link.SQUADLAND a,
.blog #header .nav-link.Squadland a,
.blog--post_detail #header .nav-link.Squadland a { text-decoration: underline; }
.about #header .nav-link.Us a { text-decoration: underline; }
.careers #header .nav-link.Careers a { text-decoration: underline; }
.studio #header .nav-link.Content a { text-decoration: underline; }
.studio--study_detail #header .nav-link.Content a { text-decoration: underline; }

// mobile nav overwrites
#mobileNav nav {
  @extend .fkgr;
  font-size: 24px;
  text-transform: none;

  a {
    color: $black;
    text-decoration: none;

    .homepage &,
    #footer &,
    .about & {
      color: $black;
    }
  }

  li:last-child {
    a {
      @extend .btn;
      margin-top: 20px;
      padding-bottom: 0.75rem;
      padding-top: 0.75rem;
      position: relative;
      text-align: center;
      width: 100%;
    }
  }
}