//
// Colors
//

$white: #fff;
$black: #1e1e25;

$tan: #f5eeea;
$blue: #138bfa;
$green: #00d67c;
$pink: #ffc8b6;
$yellow: #F5F967;

.bg--tan {
  background-color: $tan;
}

.bg--yellow {
  background-color: $yellow;
}

.bg--peach {
  background-color: $pink;
}

.bg--blue {
  background-color: $blue;

  a,button { color: $white; }
}

.bg--green {
  background-color: $green;
}