//
// Buttons
//

.btn {
  background-color: $blue;
  border-color: $blue;
  border-radius: 8px;
  color: $white;
  display: inline-block;
  @extend .smono;
  line-height: 1.25;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: uppercase;

  &.btn-secondary {
    background-color: $yellow;
    color: $black;
  }
}

.load--more {
  margin: 0 auto;
  max-width: 250px;
}