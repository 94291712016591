//
// Header
//

#header {
  position: relative;
  z-index: 999;

  hr {
    border-color: $black;
    margin: 0;
    opacity: 1;
    padding: 0;

    .homepage &,
    .about & {
      border-color: $white;
    }
  }

  .approach & {
    @extend .bg--yellow;
  }

  .work & {
    @extend .bg--tan;
  }

  .blog & {
    @extend .bg--peach;
  }

  .about & {
    @extend .bg--blue;
  }

  .studio & { 
    @extend .bg--green;
  }
}

.brand {
  display: inline-block;

  svg {
    fill: $black;

    .homepage &,
    .about & {
      fill: $white;
    }
  }
}

// banner
#banner {
  background-color: $blue;
  color: $white;
  padding: 10px 15px;
  text-align: center;

  p {
    margin: 0;
    padding: 0;
  }

  a {
    color: $white;
  }
}

// mobile
.header--main {
  display: block;

  @include mobile { display: none; }
}

.header--mobile {
  display: none;

  @include mobile { display: block; }

  #mobile--nav_trigger {
    appearance: none;
    background-color: transparent;
    border: none;
    height: 10px;
    margin: 0;
    padding: 0;
    position: relative;
    top: -6px;
    width: 30px;
    
    span {
      background-color: $black;
      display: block;
      height: 2px;
      position: absolute;
      width: 100%;

      &:nth-of-type(1) { top: 0; }
      &:nth-of-type(2) { bottom: 0; }

      .homepage &,
      #footer &,
      .about & {
        background-color: $white;
      }
    }
  }
}

#mobileNav {
  background-color: $tan;
  display: none;
  height: 1000px;
  position: fixed;
  top: 0;
  width: 100%;

  .brand svg { fill: $black; }

  &.is--open {
    display: block;
  }

  #mobile--nav_closer {
    appearance: none;
    background-color: transparent;
    border: none;
    height: 10px;
    margin: 0;
    padding: 0;
    position: relative;
    top: -3px;
    width: 30px;

    span {
      background-color: $black;
      display: block;
      height: 2px;
      position: absolute;
      width: 100%;

      &:nth-of-type(1) {
        top: 0;
        transform: rotate(45deg);
      }
      &:nth-of-type(2) {
        top: 0;
        transform: rotate(-45deg);
      }
    }
  }
}