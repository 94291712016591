//
// Blog Posts
//

.back {

  img {
    margin-top: -2px;
    position: relative;
  }

  a {
    @extend .smono;
    color: $black;
    text-decoration: none;
  }
}

.blog--post {

  &.filtering {
    display: none;
  }

  &.is--filtered {
    display: block;
  }

  a {
    color: $black;
    text-decoration: none;
  }

  .post--lead,
  .post--author,
  .post--cat {
    @extend .smono;
  }
}

.post--cat {
  @extend .smono;
  background-color: transparent;
  border: 1px solid $black;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 8px;
}

.cover--photo {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  width: 100%;

  &.cover--photo_case-study { height: 450px; }

  @include mobile {
    height: 300px !important;
  }
}

.blog--content {
  margin-top: 60px;
  padding-bottom: 6rem;

  a {
    color: $blue;
    text-decoration: underline;

    &:hover { text-decoration: none; }
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0; width: 100%; height: 100%; }

//
// Case Studies
//

.case--study {

  a {
    color: $black;
    text-decoration: none;
  }

  .case--lead {
    @extend .smono;
  }
}

//
// People
//

#team,
#leadership {
  
  .person {
    display: none;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      display: block;
    }

    &.is--visible { display: block; }
  }
}

.homepage #team,
.homepage #case--study_list {

  .person {
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      display: none;
    }
  }

  .case--study {
    display: none;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      display: block;
    }
  }
}

.person {

  a {
    color: $black;
    text-decoration: none;
  }

  .bio {
    @extend .smono;

    a {
      color: $black;
      text-decoration: underline;

      &:hover { text-decoration: none; }
    }
  }

  &.is--visible {
    display: block;
  }
}