//
// Mixins
//

@function calculate-rem($size) {
	$remSize: $size / 16px;
	@return #{$remSize}rem;
}

@mixin font-size($size) {
	font-size: $size; //Fallback in px
	font-size: calculate-rem($size);
}

@mixin border-radius($radius) {
	border-radius: $radius;
		-moz-border-radius: $radius;
		-ms-border-radius: $radius;
		-webkit-border-radius: $radius;
}

@mixin box-shadow($content) {
	filter: drop-shadow($content rgba(0,0,0,0.2));
		-webkit-filter: drop-shadow($content rgba(0,0,0,0.2));
}

@mixin transition($content) {
	transition: $content ease-in-out;
		-ms-transition: $content ease-in-out;
		-moz-transition: $content ease-in-out;
		-webkit-transition: $content ease-in-out;
}

@mixin transform-scale($x,$y) {
	-webkit-transform: scale($x,$y);
	-moz-transform: scale($x,$y);
	-ms-transform: scale($x,$y);
	-o-transform: scale($x,$y);
	transform: scale($x,$y);
}

// marquee style animation
@keyframes slider {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-205%, 0);
	}
}

// rock animation
@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(3deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(-3deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

//
// Media Query Ranges
//

$small-range: (0px, 767px); // 0 - 767
$medium-range: (768px, 1024px); // 768 - 1024
$large-range: (1025px, 1440px); // 1025 - 1440

$screen: "only screen";

$landscape: "#{$screen} and (orientation: landscape)";
$portrait: "#{$screen} and (orientation: portrait)";

$small-up: $screen;
$small-only: "#{$screen} and (max-width: 767px)";

$small-and-medium: "#{$screen} and (max-width: #{upper-bound($medium-range)})";

$medium-up: "#{$screen} and (min-width:768px)";
$medium-only: "#{$screen} and (min-width:768px) and (max-width:1024px)";

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})";
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";

//
// Media query mixins various screen sizes and combinations
//
@mixin desktop-up() {
	@media #{$large-up} {
		@content;
	}
}

@mixin desktop() {
	@media #{$large-only} {
		@content;
	}
}

@mixin tablet-desktop() {
	@media #{$medium-up} {
		@content;
	}
}

@mixin tablet() {
	@media #{$medium-only} {
		@content;
	}
}

@mixin tablet-mobile() {
	@media #{$small-and-medium} {
		@content;
	}
}

@mixin mobile() {
	@media #{$small-only} {
		@content;
	}
}