//
// Footer
//

footer {
    background-color: $blue;
    color: $white;
    margin: 0;
    padding: 60px 0 0;

    hr {
        border-color: $white;
        margin: 0;
        opacity: 1;
        padding: 0;
    }

    nav img { max-height: 20px; }

    p {
        margin: 0;
        padding: 0;
    }

    .copyright {
        @extend .smono;
    }
}