//
// LaunchSquad 2023
// Designed by @thewildshore
// Built by @pensocostudio
//

@import '_partials/colors',
        '_partials/mixins',
        '_partials/fonts';

body {
  color: $black;
  @extend .fkgr;
  font-display: swap;
  font-size: 22px;

  &.modal--open {
    overflow: hidden;
  }
}

@import '_partials/header',
        '_partials/footer',
        '_partials/navigation',
        '_partials/buttons',
        '_partials/components',
        '_partials/case',
        '_partials/posts';