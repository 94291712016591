//
// Webfonts & Typography
//

// FK Grotesk Neue Regular
@font-face {
  font-family: 'FKGR';
  src: url('../_webfonts/FKGroteskNeue-Regular.woff2') format('woff2'),
    url('../_webfonts/FKGroteskNeue-Regular.woff') format('woff'),
    url('../_webfonts/FKGroteskNeue-Regular.ttf') format('truetype');
  font-display: swap;
}

.fkgr {
  font-family: 'FKGR', sans-serif;
}

// FK Grotesk Neue Medium
@font-face {
  font-family: 'FKGM';
  src: url('../_webfonts/FKGroteskNeue-Medium.woff2') format('woff2'),
    url('../_webfonts/FKGroteskNeue-Medium.woff') format('woff'),
    url('../_webfonts/FKGroteskNeue-Medium.ttf') format('truetype');
  font-display: swap;
}

.fkgm {
  font-family: 'FKGM', sans-serif;
}

// Soehne Mono Buch
//soehne-mono-buch
@font-face {
  font-family: 'smono';
  src: url('../_webfonts/soehne-mono-buch.woff2') format('woff2');
  font-display: swap;
}

.smono {
  font-family: 'smono', sans-serif;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

  .smono.lg {
    font-size: 15px;

    @include mobile {
      font-size: 13px;
    }
  }

h1,
h2,
h3,
h4,
h5 {
  @extend .fkgr;
  letter-spacing: -1px;

  a {
    color: $black;
    text-decoration: underline;

    &:hover { text-decoration: none; }
  }
}

h1,.h1 {
  font-size: 62px;
  line-height: 110%;

  @include mobile {
    font-size: 32px;
  }
}

h2,.h2 {
  font-size: 52px;
  line-height: 120%;

  @include mobile {
    font-size:26px;
  }
}

h3,.h3 {
  @extend .fkgm;
  font-size: 42px;
  line-height: 120%;

  @include mobile {
    font-size: 22px;
  }
}

h4,.h4 {
    font-size: 42px;
    line-height: 120%;

    @include mobile {
        font-size: 22px;
    }
}

h5,.h5 {
  font-size: 26px;
  line-height: 120%;

  @include mobile {
    font-size: 18px;
  }
}

h6,.h6 {
  @extend .smono;
}