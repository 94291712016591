//
// Components
//

hr {
  border-color: $black;
  margin: 0;
  padding: 0;
  opacity: 1;
}

.homepage--lead-in {

  h3 {
    position: relative;

    img {
      height: 50px;
      position: relative;
      margin-top: -8px;
    }
  }
}

.homepage-banner {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  margin-top: -90px;
  min-height: 700px;
  position: relative;
  width: 100%;

  @include mobile {
    min-height: 300px;
  }

  img {
    display: block;
    left: 50%;
    max-width: 800px;
    position: absolute;
    top: 50%;
    transform: translate(-400px,-60px);

    @include mobile {
      margin: 0 auto;
      max-width: 80%;
      left: 0;
      position: relative;
      top: 150px;
      transform: translate(0,0);
    }
  }
}

// page lead in
.lead--in {
  padding: 100px 0;

  @include mobile {
    padding: 30px 0;
  }

  img {
    margin-top: -30px;
    max-width: 440px;
    position: relative;
    z-index: 99;

    @include mobile { display: none; }
  }

  &.bg--blue {
    color: $white;
  }

  iframe {
    height: 1500px;
    width: 100%;
  }
}

.case--study_detail .lead--in {
  @include mobile {
    margin-bottom: 50px;
  }
}

// approach story
.story {

  hr {
    border-color: $black;
    margin: 0;
    opacity: 1;
    padding: 0;

    .approach & {
      background-color: $yellow;
    }
  }
}

// masonry styles
.approach--values {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.approach--values_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;

  > div { 
    margin-bottom: 90px;
  }

  img {
    padding: 0 60px;

    @include mobile {
      padding: 0 20px;
    }
  }

  p.value {
    background-position: left;
    background-repeat: no-repeat;
    background-size: auto 28px;
    margin: 10px 60px;
    padding-left: 36px;

    @include mobile {
      margin: 10px 20px;
    }
  }
}

.what--expertise {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.expertise--details {
  

  > div { 
    margin-bottom: 90px;
  }

  h4 { font-size: 32px; }

  img {
    padding: 0 30px;
  }

  .what--img {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
    margin: 0 30px;

    @include mobile {
      margin: 0;
    }
  }

  .expertise {
    padding: 0 30px;

    @include mobile {
      padding: 0;
    }
  }
}

.text--sm p {
  font-size: 18px;
}

.location {
  p { font-size: 18px; }
}

// values
.our--values {

  figcaption { max-width: 80%; }

  button {
    background-color: transparent;
    color: $black;
    padding: 0;
    text-align: left;

    &.active {
      background-color: transparent !important;
      color: $blue !important;
    }
  }
}

// about
.inline--icon {
  height: 50px;
  margin-right: 10px;
}

.tab-content {
  a {
    color: $black;
    text-decoration: underline;

    &:hover { text-decoration: none; }
  }
}

// expertise
.what-we-do {
  background-color: $tan;

  .btn {
    margin-left: 70px;

    @include mobile {
      display:block;
      margin-left: 0;
    }
  }

  #expertise-tab {

    button {
      background-position: left;
      background-repeat: no-repeat;
      background-size: 0 0;
      color: $black;
      padding: 0 0 0 70px;
      text-align: left;

      &.active {
        background-color: transparent;
        background-size: auto;
        color: $blue;

        @include mobile {
          background-size: 30px auto;
          padding-left: 40px;
        }
      }

      @include mobile {
        padding-left: 40px;
      }
    }
  }

  .expertise {
    h4 {
      background-position: 0 5px;
      background-repeat: no-repeat;
      background-size: 50px auto;
      padding-left: 60px;
    }
  }
}

.blog-spotlight {
  h4.sec--header {
    background-position: left;
    background-repeat: no-repeat;
    background-size: 60px auto;
    padding-left: 80px;
  }
}

.has--bg-img {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 60px auto;
  padding-left: 70px;
  position: relative;

  @include mobile {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.has--img {
  position: relative;

  img {
    height: 70px;
    margin-right: 10px;
    position: relative;
    top: 10px;
  }
}

// inline icons
.inline--images {

  h2,h3,h4,h5 {
    display: inline;
  }

  p {
    display: inline;
  }

  .block-img {
    display: inline;
    margin: 0;
    padding: 0;

    img {
      top: -8px;
      max-height: 50px;
      position: relative;

      @include mobile {
        max-height: 25px;
        top: -2px;
      }
    }
  }

  &.sm {
    img {
      max-height: 35px;
      top: -3px;
    }
  }

  &.smm {
    img {
      max-height: 35px;
      top: 12px;
    }
  }

  h2 + .block-img {
    img { top: 0; }
  }
}

.metric {
  position: relative;

  .metric--heading {
    @extend .h4;
    display: block;
  }

  .metric--copy {
    @extend .smono;
    display: inline-block;
    max-width: 85%;
  }

  &::after {
    content: '';
    display: block;
    background-color: $black;
    height: 100%;
    position: absolute;
    right: 30px;
    top: 0;
    width: 1px;
  }

  &:last-child::after { display: none; }
}

.metric--points {

  ul {
    border-bottom: 1px solid $black;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-top: 1px solid $black;
      margin: 0;
      padding: 15px 30px;
      position: relative;

      &::before {
        background-color: $black;
        border-radius: 50%;
        content: '';
        display: block;
        height: 8px;
        left: 10px;
        position: absolute;
        top: 26px;
        width: 8px;
      }
    }

  }
}

// careers
.working-here {
  
  .container {
    padding-top: 100px;
  }
}

.locations {
  color: $white;

  figcaption {
    background-position: left;
    background-repeat: no-repeat;
    background-size: auto 28px;
    margin: 10px 0;
    padding-left: 36px;
  }

  #locations-tab {

    button {
      background-position: left;
      background-repeat: no-repeat;
      background-size: 0 0;
      color: $white;
      padding: 0;
      text-align: left;

      &.active {
        background-color: transparent;
        background-size: auto;
        color: $black;
      }
    }
  }
}

.careers--blurb {

  .row h4 {
    margin-bottom: 2.5rem;
  }

  .row a {
    @extend .btn;
    padding-bottom: 0.375em;
    padding-top: 0.375em;
    text-decoration: none;
  }
}

.video {
  padding: 60px 0;
}

.openings {

  .disclaimer {
    @extend .smono;
  }
}

// work
.latest-work {
  background-color: $yellow;
}

#work--list {

  .cat--desc {
    display: none;
  
    &.is--visible { display: block; }
  
  }
}

// blog landing
#squad-logo {
  animation: rotation 3s infinite linear;
  margin-top: 0;
  max-width: 90%;
}

#blog--list {

  &.show--culture {
    article.Perspective,
    article.Squadtalks,
    article.Success { display: none; }
  }

  &.show--perspective {
    article.Culture,
    article.Squadtalks,
    article.Success { display: none; }
  }

  &.show--squadtalks {
    article.Perspective,
    article.Culture,
    article.Success { display: none; }
  }

  &.show--success {
    article.Culture,
    article.Perspective,
    article.Squadtalks { display: none; }
  }
}

.more--clients {

  img {
    display: inline-block;
    margin: 0 auto;
    max-height: 60px;
    opacity: 0.5;
  }
}

// scrolling marquee
.marquee--wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;

  .marquee--left {
    background: linear-gradient(to right, rgba(245,249,103,1), rgba(245,249,103,0.00));
    height: 100%;
    left: 0;
    position: absolute;
    width: 60px;
    z-index: 99;
  }

  .marquee--right {
    background: linear-gradient(to left, rgba(245,249,103,1), rgba(245,249,103,0.00));
    height: 100%;
    right: 0;
    position: absolute;
    width: 60px;
    z-index: 99;
  }

  img {
    margin: 0 60px;
    max-height: 60px;
    max-width: auto;
  }
}

.marquee--slider {
  animation: 45s linear infinite slider;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

// slices
.content-slice {
  margin-bottom: 0;
}

.component--video {
  padding-bottom: 30px;
  padding-top: 30px;
  
  .posterframe {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 750px;
  }
}

.component--split_image {
  padding-bottom: 30px;

  .colored--bg {
    background-color: $green;
    height: 100%;
    padding: 30px;
  }

  & + .component--video { padding-top: 0; }
}

.component--execution {
  color: $white;
  padding: 100px 0;

  img {
    max-height: 40px;
    width: auto;
  }

  p {
    margin: 0;
  }

  .press--box {
    border: 1px solid $white;
    border-radius: 2px;
    padding: 30px;
    position: relative;

    a {
      text-decoration: none;

      &:hover { text-decoration: underline; }
    }
  }
}

.component--image {
  margin-bottom: 30px;
}

.case--study_detail .component--image {
  .offset-md-1 { margin-left: 0; }

  .col-md-10 { width: 100%; }
}

// locations
.locations {
  margin-top: 60px;
}

// careers
#openRoles {
  border-bottom: 1px solid $black;
  font-size: 18px;

  .open--role {
    cursor: pointer;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  p {
    margin: 0;
    padding: 0;
  }
}