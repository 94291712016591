//
// Case Studies
//

.case--study {

  &.filtering {
    display: none;
  }

  &.is--filtered {
    display: block;
  }
}

.cover--photo {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  width: 100%;
}

.hero {
  position: relative;
  z-index: 99;

  .about & {
    margin-top: -400px;
  }
}

.featured--image {
  margin: 0;
  position: relative;

  @include mobile {
    margin-bottom: 60px;
  }

  img { width: 100%; }

  .client--logo {
    background-color: $black;
    margin: 30px 0 0 -60px;
    padding: 20px 20px 20px 80px;
    position: absolute;

    img {
      max-height: 50px;
      width: auto;

      @include mobile {
        max-height: 30px;
      }
    }
  }
}

.positioning {
  margin-bottom: 0;
  margin-top: -300px;
  padding: 400px 0 100px;
  position: relative;

  & + .component--split_image {
    margin-top: 30px;
  }
}

.component--execution {
  & + .component--split_image {
    margin-top: 30px;
  }
}

// client logos
#client--logos {
  
  .logo {
    display: none;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      display: block;
    }

    &.is--visible { display: block; }
  }
}

// related case studies
.is--related {
  display: none;

  &.is--visible { display: block; }
}